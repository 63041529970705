
.footer {
  padding: 20px;
  background-color: rgb(22, 22, 22);
  text-align: start;
  
}

.footer-social{
flex-direction: row;
display: flex;
color: white;
font-size: 1rem;
font-weight: 500;
align-items: center;
}

hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #8c8c8ca5, #333, #8b8989);
  margin: 10px 0;
}

.footer-text p{
  color: #fff;
  font-size: 13px;
  width: 100%;
}



/* .footer {
  padding: 20px;
  background-color: var(--background-color);
  text-align: center;
} */

/* .footer-social-icons a {
  margin: 0 10px;
} */

/* .footer-social-icons img {
  width: 40px;
  height: auto;
  transition: transform 0.3s ease;
} */

/* .footer-social-icons img:hover {
  transform: scale(1.1);
} */
