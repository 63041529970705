

.project-card {
  background-color: var(--card-color);
  border-radius: 20px;
  /* border-color: #e9f6fd; */
  border-width: 1px;
  /* border-style: solid; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: start;
  position: relative;
  overflow: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.project-card:hover {
  transform: scale(1, 1.05);
}

.project-card-title {
  font-weight: 500;
  margin-bottom: 1px;
  font-size: 16px;
  width: 100%;
}


.project-card-description {
  font-size: 15px;
  font-weight: 300;
  opacity: .8;
  margin-top: 3px;
 margin-bottom: 6px;
 display: -webkit-box;
 width: 100%;
  overflow:hidden;
  -webkit-line-clamp: 4;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* max-lines: 2;
  white-space:nowrap; */
  text-overflow: ellipsis;
  /* word-break: break-all; */
  /* color: #cfcfcf; */
}

.spacer {
  flex-grow: 1; /* Takes up all available space */
}

.project-card-link {
  color: var(--link-color);
  font-weight: 500;
  text-decoration:underline;
  margin-bottom: 0px;
  font-size: 14px;
  display: inline-block;
}

.project-card-image {
  align-items: end;
  display: flex;
  justify-content: center;
  object-fit: fill;
  width: 100%;
  margin-top: 20px;
}

.project-card-image img {
  display:flex;
  border-radius: 7px;
  height: auto;
  max-height: 600px;
  object-fit: fill;
  width: 100%;
  min-height: 130px;
  box-sizing: border-box;
  margin-top: 10px;
  align-items:flex-end;
}

@media (max-width: 968px) {
  /* .project-card { */
  /* background-color: var(--card-color); */
  /* border-radius: 20px; border-color: #e9f6fd; */
  /* border-width: 1px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: start;
  position: relative;
  overflow: hidden;
  width: 100%;
} */

.project-card-title {
  font-weight: 500;
  margin-bottom: 1px;
  font-size: 16px;
}


.project-card-description {
  font-size: 15px;
  font-weight: 300;
  opacity: .8;
  margin-top: 3px;
 margin-bottom: 6px;
 display: -webkit-box;
  overflow:hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* max-lines: 2;
  white-space:nowrap; */
  text-overflow: ellipsis;
  /* word-break: break-all; */
  /* color: #cfcfcf; */
}

.project-card-link {
  color: var(--link-color);
  font-weight: 500;
  text-decoration:underline;
  margin-bottom: 0px;
  font-size: 14px;
  display: inline-block;
}



}

/* Media queries for responsive design */
@media (max-width: 468px) {
  .project-card-title {
  font-weight: 500;
  margin-bottom: 1px;
  font-size: 16px;
}


.project-card-description {
  font-size: 15px;
  font-weight: 300;
  opacity: .8;
  margin-top: 3px;
 margin-bottom: 6px;
 display: -webkit-box;
  overflow:hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* max-lines: 2;
  white-space:nowrap; */
  text-overflow: ellipsis;
  /* word-break: break-all; */
  /* color: #cfcfcf; */
}

.project-card-link {
  color: var(--link-color);
  font-weight: 500;
  text-decoration:underline;
  margin-bottom: 0px;
  font-size: 14px;
  display: inline-block;
}

/* .project-page-grid {
  display: grid;
  grid-column: 1/span 12;
  grid-template-columns: repeat(1, minmax(420px, 1fr));
  justify-content: center;
  gap: 20px;

} */
}