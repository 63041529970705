:root {
  --background-color: #e7e7e7;
  --text-color: #000000;
  --tag-background-color: #0066cc;
  --tag-text-color: #ffffff;
  --link-color: #5f9dee;
  --button-bg-color: #1a73e8;
  --button-hover-bg-color: #0f5bb5;
  --card-color: var(--background-color);
  --primary-color: #174ea5;
}

[data-theme="dark"] {
  --background-color: #252627;
  --text-color: #ffffff;
  --tag-background-color: #0066cc;
  --tag-text-color: #ffffff;
   --card-color: #343637;
}

[data-theme="light"] {
  --background-color: #e7e7e7;
  --text-color: #000000;
  --tag-background-color: #0066cc;
  --tag-text-color: #ffffff;
   --card-color: #f6f6f6;
}